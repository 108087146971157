<template>
  <div class="security m:mb-10 mb-0 pb-24 sm:pb-0">
    <div class="security-top">
      <div class="security-top__title">
        <h4>{{ $t('security.title') }}</h4>
        <p>{{ $t('security.sub_title') }}</p>
      </div>
      <div class="security-top__img">
        <img src="img/security.svg" alt="">
      </div>
    </div>
    <div class="security-body bg-base">
      <div v-for="security in securityItems" :key="security.title" class="security-item">
        <div class="security-item__title">
          <h4>{{ $t(security.title) }}</h4>
          <p>{{ $t(security.description) }}</p>
        </div>
        <div v-if="security.hasOwnProperty('verification')" class="security-item__verification flex items-center">
          <i :class="[security.verification ? 'icon-chek' : 'icon-plus-btn']"></i>
          <div class="value">{{ security.verification ? security.value : $t('security.not_set_yet') }}</div>
        </div>
        <div class="security-item__btn">
          <button class="btn btn-gray-light px-6 py-1.5">{{ $t('general.change')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Security',
  data: () => ({
    securityItems: [
      {
        title: 'security.set_password',
        description: 'security.account_login_password'
      },
      {
        title: 'security.fund_password',
        description: 'security.password_manage_account_funds'
      },
      {
        title: 'general.mobile_verify',
        description: 'security.protect_transactions_account',
        value: '861****918',
        verification: true
      },
      {
        title: 'security.email_address',
        description: 'security.protect_transactions_account',
        verification: false
      },
      {
        title: 'security.real_name_authentication',
        description: 'security.verify_failed',
        verification: false
      },
      {
        title: 'security.device_management',
        description: 'security.recent_login_activities'
      },
      {
        title: 'security.bank_cards',
        description: 'security.can_3_bund_savings_bank_cards',
        verification: false
      },
      {
        title: 'security.addresses',
        description: 'security.ensure_withdrawal_address_correct',
        verification: false
      }
    ]
  })
}
</script>
